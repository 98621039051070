<template lang="pug">
div
  div
    driver-form-modal(
      ref="form"
      @close="showAdd=false"
      :visible="showAdd"
      :currentDriver="currentDriver"
      @onSave="onDriverChanged"
      @onDelete="onDriverDeleted"
      )
  card(title='Drivers')
    div.row
      .col-12
        .d-flex.justify-content-end.mb-5
          matador-search#search-box(placeholder='Search Drivers' autocomplete='off')
          matador-button.ml-3(@click="clickAdd", icon="fa fa-plus")
            | Add Driver
        keep-alive
          .table-responsive
            bs-table(
              ref="table"
              :columns="table.columns",
              :options="table.options",
              :data="userList",
              @on-post-body="vueFormatterPostBody"
            )
</template>
<script>
import tableMixin from "@/mixins/table";
import ChevronDown from "vue-material-design-icons/ChevronDown.vue";
import ChevronUp from "vue-material-design-icons/ChevronUp.vue";
import Plus from "vue-material-design-icons/Plus.vue";
import EyeOutline from "vue-material-design-icons/EyeOutline.vue";
import EyeOffOutline from "vue-material-design-icons/EyeOffOutline.vue";
import DriverFormModal from "./DriverFormModal.vue";
import Dropdown from "@/components/Dropdown.vue";

import { getDriver2 } from "@/utils/api";

export default {
  mixins: [tableMixin],

  components: {
    ChevronDown,
    ChevronUp,
    EyeOutline,
    EyeOffOutline,
    Plus,
    DriverFormModal
  },

  data() {
    return {
      currentDriver: {},
      position: "",
      inputData: {
        username: "",
        fullname: "",
        email: "",
        position: "",
        organization: "",
        password: "",
        password_confirm: ""
      },
      showAdd: false,
      userList: [],
      positionList: [],
      organizationList: [],
      hidepassword: true,
      hideconfirmpassword: true,
      perPage: 10,
      table: {
        options: {
          ajax: this.fetchDriverList,
          search: true,
          pagination: true,
          showSearchButton: true,
          searchSelector: "#search-box",
          pageSize: this.perPage,
          sortable: true,
          sortDesc: true,
          paginationHAlign: "center",
          paginationDetailHAlign: "right",
          paginationParts: ["pageInfo", "pageList"],
          classes: ["table", "table-hover"],
          loadingFontSize: 16,
          sidePagination: "server"
        },
        columns: [
          {
            title: "Driver Name",
            field: "name",
            searchable: true,
            sortable: true,
            widthUnit: "%",
            width: "22"
          },
          {
            title: "Phone",
            field: "phone",
            sortable: true,
            searchable: false,
            widthUnit: "%",
            width: "22"
          },
          {
            field: "description",
            title: "Description",
            sortable: true,
            searchable: false,
            widthUnit: "%",
            width: "22"
          },
          {
            field: "device_name",
            title: "Device Name",
            sortable: true,
            searchable: false,
            widthUnit: "%",
            width: "22"
          },
          {
            field: "",
            title: "",
            formatter: (value, row) => {
              return this.vueFormatter({
                template: `<div>
  <dropdown tag="a" class="dropdown-toggle-no-caret" icon="mdi mdi-dots-vertical matador-primary-color h4">
    <li class="p-3 matador-text-03" v-on:click="onEdit(row)">Edit Driver</li>
    <li class="p-3 matador-text-03" v-on:click="onDelete(row)">Delete Driver</li>
  </dropdown>
</div>`,
                data: { row, value },
                methods: {
                  onEdit: this.clickEdit,
                  onDelete: this.clickDelete
                },
                components: {
                  Dropdown
                }
              });
            },
            sortable: false,
            searchable: false,
            widthUnit: "%",
            width: "5"
          }
        ]
      }
    };
  },
  methods: {
    customSort(sortName, sortOrder, data) {
      var order = sortOrder === "desc" ? -1 : 1;
      data.sort(function(a, b) {
        var aa = +(a[sortName] + "").replace(/[^\d]/g, "");
        var bb = +(b[sortName] + "").replace(/[^\d]/g, "");
        if (aa < bb) {
          return order * -1;
        }
        if (aa > bb) {
          return order;
        }
        return 0;
      });
    },
    clickAdd() {
      this.currentDriver = {};
      this.showAdd = true;
    },
    clickEdit(device) {
      this.currentDriver = device;
      this.showAdd = true;
    },
    clickDelete(device) {
      this.currentDriver = device;
      this.$refs.form.delete();
    },
    fetchDriverList(params) {
      const responseHandler = ({ data, status }) => {
        if (status !== 200) throw new Error("Failed to load list users");

        let response = data.result.data;

        this.userList = response;
        let pagination = data.result.pagination;

        if (!this.userList || !this.userList.length)
          return params.success(false);
        let resource = {
          rows: this.userList,
          total: pagination.total_data || 0,
          totalNotFiltered: pagination.total_data || 0
        };

        setTimeout(() => params.success(resource), 500);
      };

      let page = params.data.offset / params.data.limit || 0;
      let payload = {
        limit: params.data.limit || 10,
        page: page + 1,
        search: params.data.search
      };

      getDriver2(payload)
        .then(responseHandler)
        .catch(e => {
          let err = e.response ? e.response.data : e;
          this.$toast.error(err.message || "Failed to load user list");
        });
    },
    onDriverChanged(device) {
      this.showAdd = false;
      this.$refs.table.refresh();
      this.$toast.open("Successfully save driver data.");
    },
    onDriverDeleted() {
      this.$refs.table.refresh();
      this.$toast.open("Successfully delete driver data.");
    }
  },
  mounted() {
    // reset search if clear
    var that = this;
    window.$(".search input").on("search", function() {
      if (!window.$(this).val()) {
        that.$refs.table.resetSearch();
      }
    });
  }
};
</script>
<style>
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: table;
  background-color: rgba(0, 0, 0, 0.4);
  transition: opacity 0.3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.header {
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.05);
}
.content-filter {
  margin-top: -1rem;
}
.tab-filter {
  min-width: 200px;
  border-right: 2px solid #eaeaec;
  padding-right: 0.5rem;
}
.badge-circle {
  border-radius: 50%;
  width: 26px;
  height: 26px;
  padding: 4px;

  text-align: center;

  font: 10pt Arial, sans-serif;
  font: 10pt Arial, sans-serif;
}
.p-viewer {
  float: right;
  margin-right: 10px;
  margin-top: -33px;
  position: relative;
  z-index: 1;
  cursor: pointer;
}
</style>
