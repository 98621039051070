<template lang="pug">
transition(name="modal")
  div
    .modal-mask(v-if="visible")
      .modal-wrapper
        .modal-dialog.modal-dialog-scrollable(role="document")
          .modal-content
            .modal-header
              .h5.font-weight-bold.card-title.px-3 {{ title }} Driver
              button.close(
                type="button",
                data-dismiss="modal",
                aria-label="Close",
                @click="onClose()"
              )
                span.pr-3(aria-hidden="true", @click="showAdd = false") &times;
            .modal-body
                form.px-3.mt-3
                  .form-group
                    label Driver Name
                    //- select.custom-select.form-control(type='text',v-model="user_id"
                    //-  :class="{ 'matador-placeholder': user_id === ''}")
                    //-   option(value="" selected disabled) Driver Name
                    //-   option(v-for="item in userList" :value="item.user_id") {{item.name}}

                    multiselect(
                      v-model="selectedUser",
                      track-by="user_id",
                      label="name"
                      placeholder="Choose Driver Name",
                      :options="userList",
                      :searchable="true",
                      :allow-empty="false",
                      :closeOnSelect="true"
                      ref="selectUser"
                    )
                      .d-flex.align-items-start(
                        slot="afterList"
                      )
                        a.p-2(
                          href="javascript:;",
                          role="button",
                          @click="onCreateNewUserClick()") 
                          .m-0.mr-1.primary-color
                            plus-circle.mr-1
                            | Create New User
                  .form-group.d-flex.flex-column.driver-new-user(v-if="user_id === 'new_user'")
                    form.px-3.mt-3
                      .form-group
                        label(for='username') Username
                        input#username.form-control(type='text' placeholder='Insert username', v-model="username")
                      .form-group
                        label(for='fullname') Full Name
                        input#fullname.form-control(type='text' placeholder='Insert your fullname', v-model="name")
                      .form-group
                        label(for='email') Email
                        input#email.form-control(type='text' placeholder='example@gmail.com', v-model="email")
                      .form-group(style="position: relative" )
                        label(for='inputPassword') Password
                        input#inputPassword.form-control(:type='passwordType' v-model="password" placeholder='Password' required='')
                        a.h3(@click="togglePassword" href="javascript:;" style="position: absolute;bottom: 1px;margin: 0;right: 10px;")
                          .text-muted(v-if="passwordType === 'password'")
                            eye-off-outline
                          .text-primary(v-if="passwordType === 'text'")
                            eye-outline
                  .form-group
                    label(for='username') Phone
                    input#username.form-control(type='text' placeholder='Insert Phone Number',
                     @change="changePhone", @input="changePhone", :value="inpNum")
                  .form-group
                    label Description
                    textarea.form-control(type='text' placeholder='Insert description', v-model="description" rows="6")
                  .form-group
                    label Device Name
                    multiselect(
                      v-model="selectedVehicle",
                      track-by="device_unit_id",
                      label="description"
                      placeholder="Choose Device Name",
                      :options="deviceList",
                      :searchable="true",
                      :allow-empty="false",
                      :closeOnSelect="true"
                      ref="selectVehicle"
                    )
                    //- select.custom-select.form-control(type='text',v-model="device_unit"
                    //-  :class="{ 'matador-placeholder': device_unit === ''}")
                    //-   option(value="" selected disabled) Choose Vehicle Name
                    //-   option(v-for="item in deviceList" :value="item.device_unit_id") {{item.description}}

                  
                  .form-group(v-if="buttonTitle=='Add'")
                    label Country
                    .row
                      .col
                        select.custom-select.form-control(type='text' placeholder='Choose organization' v-model="country"
                        :class="{ 'matador-placeholder':country === ''}" style="color:black !important")
                          option(value="" selected disabled) Choose Country
                          option(v-for="item in countryList", :value="item.country_name") {{item.country_name}}                    
                div.float-right.mt-3
                  button.btn.btn-link.mr-3(type="button" @click="onClose()") Cancel
                  matador-button(:disabled="!isValid" @click="doSave") {{ buttonTitle }}
      confirmation-modal(
        v-if="showCancelModal"
        @close="showCancelModal = false" 
        @confirm="doClose()" 
        title="Buang Perubahan"
        confirm-text="Ya, Buang"
      )
        p.m-0
          | Apakah Anda yakin ingin membuang perubahan yang Anda buat?
    confirmation-modal(
      v-if="showDeleteModal"
      @close="showDeleteModal = false" 
      @confirm="onConfirmDelete" 
      title="Delete Driver"
      confirm-text="Yes, Delete"
    )
      p.m-0
        | Do yo want to delete this driver?
</template>

<script>
import * as yup from "yup";
import Plus from "vue-material-design-icons/Plus.vue";
import EyeOutline from "vue-material-design-icons/EyeOutline.vue";
import EyeOffOutline from "vue-material-design-icons/EyeOffOutline.vue";
import ConfirmationModal from "@/components/Matador/ConfirmationModal";
import PlusCircle from "vue-material-design-icons/PlusCircleOutline.vue";
import {
  getDeviceUnit,
  getUsersList,
  postAddDriver,
  postEditDriver,
  postRemoveDriver,
} from "@/utils/api";

let driverSchema = (isNewUser) =>
  yup.object().shape({
    user_id: yup.string().required(),
    device_unit: yup.string().required(),
    description: yup.string().required(),
    phone: yup.string().required(),
    username: isNewUser ? yup.string().required() : undefined,
    name: isNewUser ? yup.string().required() : undefined,
    email: isNewUser ? yup.string().email().required() : undefined,
  });

export default {
  data() {
    return {
      password: "",
      country: "",
      countryList: [
        {
          country_name: "Indonesia",
        },
        {
          country_name: "Myanmar",
        },
      ],
      passwordType: "password",
      created_by: localStorage.user_id,
      driver_id: "",
      user_id: "",
      device_unit: "",
      description: "",
      phone: "",
      username: "",
      name: "",
      email: "",
      selectedUser: "",
      selectedVehicle: "",
      userList: [],
      deviceList: [],
      showDeleteModal: false,
      showCancelModal: false,
      loading: false,
    };
  },
  watch: {
    currentDriver: function (newVal) {
      if (newVal) {
        this.driver_id = newVal.driver_id;
      }
      this.selectedUser =
        newVal.user_id != null
          ? {
              name: newVal.name,
              user_id: newVal.user_id,
            }
          : "";
      this.selectedVehicle =
        newVal.device_unit_id != null
          ? {
              device_unit_id: newVal.device_unit_id,
              description: newVal.device_name,
            }
          : "";
      this.country = newVal.country ? newVal.country : "";
      this.user_id = newVal.user_id || "";
      this.device_unit = newVal.device_unit_id;
      this.phone = newVal.phone;
      this.description = newVal.description;
    },
    selectedUser: function (newVal) {
      if (newVal && newVal.user_id) {
        this.user_id = newVal.user_id;
      }
    },
    selectedVehicle: function (newVal) {
      if (newVal && newVal.device_unit_id) {
        this.device_unit = newVal.device_unit_id;
      }
    },
  },
  components: {
    Plus,
    EyeOutline,
    EyeOffOutline,
    PlusCircle,
    ConfirmationModal,
  },
  props: ["close", "currentDriver", "onSave", "onDelete", "visible"],
  computed: {
    isDirty() {
      if (this.currentDriver && this.currentDriver.device_id) {
        return (
          this.currentDriver.user_id != this.user_id ||
          this.currentDriver.phone != this.phone ||
          this.currentDriver.device_unit != this.device_unit ||
          this.currentDriver.description != this.description
        );
      }
      return this.user_id || this.phone || this.device_unit || this.description;
    },
    inpNum() {
      return this.phone;
    },
    isValid() {
      return driverSchema(
        this.user_id === "new_user" ? true : false
      ).isValidSync(this.inputData);
    },
    inputData() {
      let obj = {
        created_by: this.created_by,
        driver_id: this.driver_id,
        user_id: this.user_id,
        phone: this.phone,
        username: this.username,
        name: this.name,
        email: this.email,
        device_unit: this.device_unit,
        description: this.description,
        password: this.password,
        country: this.country,
      };
      return obj;
    },
    title() {
      return !!this.driver_id ? "Edit" : "Add";
    },
    buttonTitle() {
      return !!this.driver_id ? "Update" : "Add";
    },
  },
  methods: {
    togglePassword() {
      this.passwordType = this.passwordType == "text" ? "password" : "text";
    },
    onClose() {
      if (this.isDirty) {
        this.showCancelModal = true;
        this.password = "";
      } else {
        this.doClose();
      }
    },
    doClose() {
      this.showCancelModal = false;
      this.$emit("close");
    },
    changePhone(event) {
      let val = event.target.value.trim();
      // It can only be positive integer or empty, and the rule can be modified according to the requirement.
      if (/^[0-9]\d*$|^$/.test(val)) {
        this.phone = val || "";
      } else {
        event.target.value = this.phone || "";
      }
    },
    onCreateNewUserClick() {
      this.selectedUser = { user_id: "new_user", name: "Create New User" };
      this.$refs.selectUser.toggle();
    },
    onAddOperator(newVal) {
      this.networkList = [{ name: newVal }, ...this.networkList];
      this.network_provider = newVal;
    },
    delete() {
      this.showDeleteModal = true;
    },
    doSave() {
      this.loading = true;
      const callback = !!this.driver_id ? postEditDriver : postAddDriver;
      let data = this.inputData;
      data.user_id = this.user_id === "new_user" ? undefined : this.user_id;
      callback(data).then(({ data: { result, code, messgae } }) => {
        this.loading = false;
        this.password = "";
        this.username = "";
        this.name = "";
        this.email = "";
        this.country = "";
        if (code === 200) {
          this.$emit("onSave", result);
        } else {
          this.$toast.error("Error " + messgae);
        }
      });
    },
    onConfirmDelete() {
      postRemoveDriver(this.inputData).then(
        ({ data: { result, code, message } }) => {
          this.loading = false;
          this.showDeleteModal = false;
          if (code === 200) {
            this.$emit("onDelete", result);
          } else {
            this.$toast.error("Error" + code + ": " + messgae);
          }
        }
      );
    },
    fetchUserList() {
      getUsersList().then(({ data: { code, result, message } }) => {
        this.userList = result.data;
      });
    },
    fetchDeviceUnitList() {
      getDeviceUnit().then(({ data: { code, result, message } }) => {
        this.deviceList = result;
      });
    },
  },
  mounted() {
    this.fetchUserList();
    this.fetchDeviceUnitList();
  },
};
</script>
<style lang="scss" scoped>
.driver-new-user {
  border: 1px dashed #7998b9;
  box-sizing: border-box;
  border-radius: 5px;
}
</style>
